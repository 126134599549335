import Hotels from "../../assets/svg/objectTypes/Hotels.svg";
import Gyms from "../../assets/svg/objectTypes/Gyms.svg";
import House from "../../assets/svg/objectTypes/house.svg";
import Markets from "../../assets/svg/objectTypes/Markets.svg";
import Medicine from "../../assets/svg/objectTypes/Medicine.svg";
import Transport from "../../assets/svg/objectTypes/transport.svg";
import CarRepair from "../../assets/svg/objectTypes/CarRepair.svg";
import Food from "../../assets/svg/objectTypes/Food.svg";
import Ingener from "../../assets/svg/objectTypes/Ingener.svg";
import Admin from "../../assets/svg/objectTypes/Admin.svg";
import Agro from "../../assets/svg/objectTypes/Agro.svg";
import Building from "../../assets/svg/objectTypes/Building.svg";
import Electro from "../../assets/svg/objectTypes/Electro.svg";
import Connection from "../../assets/svg/objectTypes/Connection.svg";
import Theatres from "../../assets/svg/objectTypes/Theatres.svg";
import Education from "../../assets/svg/objectTypes/Education.svg";
import Industrial from "../../assets/svg/objectTypes/Industrial.svg";
import Other from "../../assets/svg/objectTypes/Other.svg";
import { AvailableCC } from "../constants";

export type IIconClassRecord = {
  id: number;
  name: string;
  icon: any;
};

const uaIconClassMapper: Array<IIconClassRecord> = [
  {
    id: 1,
    name: "Готелі, пансіонати, гуртожитки", /// "Hotili"
    icon: Hotels,
  },
  {
    id: 2,
    name: "Спортивні споруди",
    icon: Gyms,
  },
  {
    id: 3,
    name: "Будинки житлові котеджного типу",
    icon: House,
  },
  {
    id: 4,
    name: "Торгові центри, магазини",
    icon: Markets,
  },
  {
    id: 5,
    name: "Лікарні",
    icon: Medicine,
  },
  {
    id: 6,
    name: "Транспортна інфраструктура",
    icon: Transport,
  },
  {
    id: 7,
    name: "Гаражі, стоянки, СТО",
    icon: CarRepair,
  },
  {
    id: 8,
    name: "Ресторани кафе, їдальні",
    icon: Food,
  },
  {
    id: 9,
    name: "Інженерні мережі",
    icon: Ingener,
  },
  {
    id: 10,
    name: "Адміністративні будівлі",
    icon: Admin,
  },
  {
    id: 11,
    name: "Будівлі АПК",
    icon: Agro,
  },
  {
    id: 12,
    name: "Будинки житлові багатоквартирні",
    icon: Building,
  },
  {
    id: 13,
    name: "Електромережі та електростанції",
    icon: Electro,
  },
  {
    id: 14,
    name: "Зв'язок",
    icon: Connection,
  },
  {
    id: 15,
    name: "Кінотеарти, зали, музеї",
    icon: Theatres,
  },
  {
    id: 16,
    name: "Освітні заклади",
    icon: Education,
  },
  {
    id: 17,
    name: "Промислові будівлі",
    icon: Industrial,
  },
  {
    id: 18,
    name: "Інше",
    icon: Other,
  },
];

const plIconClassMapper = [
  {
    id: 1,
    name: "Hotele, pensjonaty, hostele",
    icon: Hotels,
  },
  {
    id: 2,
    name: "Obiekty sportowe",
    icon: Gyms,
  },
  {
    id: 3,
    name: "Domy mieszkalne typu chata",
    icon: House,
  },
  {
    id: 4,
    name: "Centra handlowe, sklepy",
    icon: Markets,
  },
  {
    id: 5,
    name: "Szpitale",
    icon: Medicine,
  },
  {
    id: 6,
    name: "Infrastruktura transportowa",
    icon: Transport,
  },
  {
    id: 7,
    name: "Garaże, parkingi, stacje paliw",
    icon: CarRepair,
  },
  {
    id: 8,
    name: "Ресторани кафе, їдальні",
    icon: Food,
  },
  {
    id: 9,
    name: "Sieci inżynieryjne",
    icon: Ingener,
  },
  {
    id: 10,
    name: "Budynki administracyjne",
    icon: Admin,
  },
  {
    id: 11,
    name: "Budynki kompleksu rolno-przemysłowego",
    icon: Agro,
  },
  {
    id: 12,
    name: "Budynki mieszkalne",
    icon: Building,
  },
  {
    id: 13,
    name: "Електромережі та електростанції",
    icon: Electro,
  },
  {
    id: 14,
    name: "Зв'язок",
    icon: Connection,
  },
  {
    id: 15,
    name: "Кінотеарти, зали, музеї",
    icon: Theatres,
  },
  {
    id: 16,
    name: "Instytucje edukacyjne",
    icon: Education,
  },
  {
    id: 17,
    name: "Budynki przemysłowe",
    icon: Industrial,
  },
  {
    id: 18,
    name: "Inne",
    icon: Other,
  },
];

const noIconClassMapper = [
  {
    id: 1,
    name: "Hoteller, pensjonater, herberger",
    icon: Hotels,
  },
  {
    id: 2,
    name: "Sportsfasiliteter",
    icon: Gyms,
  },
  {
    id: 3,
    name: "Bolighus av hyttetype",
    icon: House,
  },
  {
    id: 4,
    name: "Kjøpesentre, butikker",
    icon: Markets,
  },
  {
    id: 5,
    name: "Sykehus",
    icon: Medicine,
  },
  {
    id: 6,
    name: "Transportinfrastruktur",
    icon: Transport,
  },
  {
    id: 7,
    name: "Garasjer, parkeringsplasser, bensinstasjoner",
    icon: CarRepair,
  },
  {
    id: 8,
    name: "Restauranter, kafeer, spiserom",
    icon: Food,
  },
  {
    id: 9,
    name: "Ingeniørnettverk",
    icon: Ingener,
  },
  {
    id: 10,
    name: "Administrasjonsbygg",
    icon: Admin,
  },
  {
    id: 11,
    name: "Landbruksbygg",
    icon: Agro,
  },
  {
    id: 12,
    name: "Leilighetsbygg",
    icon: Building,
  },
  {
    id: 13,
    name: "Strømnett og kraftverk",
    icon: Electro,
  },
  {
    id: 14,
    name: "Kommunikasjon",
    icon: Connection,
  },
  {
    id: 15,
    name: "Kinoer, saler, museer",
    icon: Theatres,
  },
  {
    id: 16,
    name: "Utdanningsinstitusjoner",
    icon: Education,
  },
  {
    id: 17,
    name: "Industribygg",
    icon: Industrial,
  },
  {
    id: 18,
    name: "Annen",
    icon: Other,
  },
];

const frIconClassMapper = [
  {
    id: 1,
    name: "Hôtels, pensions, dortoirs",
    icon: Hotels,
  },
  {
    id: 3,
    name: "Bâtiments résidentiels",
    icon: House,
  },
  {
    id: 4,
    name: "Centres commerciaux, boutiques",
    icon: Markets,
  },
  {
    id: 5,
    name: "Hôpitaux",
    icon: Medicine,
  },
  {
    id: 10,
    name: "Bâtiments administratifs",
    icon: Admin,
  },
  {
    id: 11,
    name: "Bâtiments du complexe agro-industriel",
    icon: Agro,
  },
  {
    id: 12,
    name: "Des bureaux",
    icon: Building,
  },
  {
    id: 15,
    name: "Cinémas, salles, musées",
    icon: Theatres,
  },
  {
    id: 17,
    name: "Bâtiments industriels",
    icon: Industrial,
  },
];

const enIconClassMapper = [
  {
    id: 1,
    name: "Hotels, boarding houses, hostels",
    icon: Hotels,
  },
  {
    id: 2,
    name: "Sports facilities",
    icon: Gyms,
  },
  {
    id: 3,
    name: "Cottage-type dwelling houses",
    icon: House,
  },
  {
    id: 4,
    name: "Shopping centers, shops",
    icon: Markets,
  },
  {
    id: 5,
    name: "Hospitals",
    icon: Medicine,
  },
  {
    id: 6,
    name: "Transport infrastructure",
    icon: Transport,
  },
  {
    id: 7,
    name: "Garages, parking lots, service stations",
    icon: CarRepair,
  },
  {
    id: 8,
    name: "Restaurants, cafes, canteens",
    icon: Food,
  },
  {
    id: 9,
    name: "Engineering networks",
    icon: Ingener,
  },
  {
    id: 10,
    name: "Administrative buildings",
    icon: Admin,
  },
  {
    id: 11,
    name: "Agricultural buildings",
    icon: Agro,
  },
  {
    id: 12,
    name: "Apartment buildings",
    icon: Building,
  },
  {
    id: 13,
    name: "Electric networks and power plants",
    icon: Electro,
  },
  {
    id: 14,
    name: "Communication",
    icon: Connection,
  },
  {
    id: 15,
    name: "Cinemas, halls, museums",
    icon: Theatres,
  },
  {
    id: 16,
    name: "Educational institutions",
    icon: Education,
  },
  {
    id: 17,
    name: "Industrial buildings",
    icon: Industrial,
  },
  {
    id: 18,
    name: "Other",
    icon: Other,
  },
];

export const iconClassMapperByRegion: Record<AvailableCC, any> = {
  ua: uaIconClassMapper,
  pl: plIconClassMapper,
  no: noIconClassMapper,
  fr: frIconClassMapper,
  en: enIconClassMapper,
};
