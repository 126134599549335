import { useMemo } from "react";
import util from "../util";
import { useTypedSelector } from "./useTypedSelector";
import useSearchParams from "./useSearchParams";

const useStoreSnippets = () => {
  const currentAccount = useTypedSelector((state) => state.currentAccount);
  const filters = useTypedSelector((state) => state.mapFilters);
  const region = useSearchParams().getSearchParam("region") as string;
  const dataset = useSearchParams().getSearchParam("dataset") as string;
  const isGuestAccount = useMemo(() => {
    return util.storeUtil.isGuestAccount(currentAccount);
  }, [currentAccount]);

  const isSignIn = !isGuestAccount;

  const isSearchDisabled =
    isGuestAccount || currentAccount?.subscribe?.permissions?.search === 0;

  const appliedFiltersCountForMain =
    util.storeUtil.getMainAppliedFiltersCount(filters);

  const isAuthWarned = useTypedSelector((state) => state.auth.isWarned);

  const isFreeAccount = util.storeUtil.isFreeAccount(currentAccount);
  return {
    isGuestAccount,
    isSearchDisabled,
    appliedFiltersCountForMain,
    isSignIn,
    isAuthWarned,
    isFreeAccount,
    region,
    dataset,
  };
};

export default useStoreSnippets;
