import React from "react";
import FeedbackForm from "../FeedbackPage/FeedbackForm";
import styled from "@emotion/styled";
import { colors, icons, zIndexes } from "../../constants";
import { Button, Tooltip } from "antd";
import ModalWithHeader from "../../share/ModalWithHeader/ModalWithHeader";
import { useTranslation } from "react-i18next";
import useMobileScreen from "../../hooks/useMobileScreen";
import { RouteNames } from "../../routes";
import { useLocation } from "react-router-dom";

const StyledBookmark = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0px;
  background-color: ${colors.redR400};
  z-index: ${zIndexes.floatingTriggers};
  border-radius: 8px 0px 0px 8px;
`;

const StyledFormContainer = styled.div`
  padding: 0px 24px;
  width: 100%;
`;
const FeedbackModal = () => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const isMobileScreen = useMobileScreen();

  const handleOpenFeedbackModal = () => {
    setModalVisible(true);
  };
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const { t } = useTranslation("translation", { keyPrefix: "feedbackPage" });
  const location = useLocation();
  const isFeedbackPage = location.pathname === RouteNames.FEEDBACK_PAGE;
  React.useEffect(() => {
    return () => {
      setModalVisible(false);
    };
  }, []);
  return (
    <>
      {!isMobileScreen && !isFeedbackPage && (
        <Tooltip placement="left" title={t("reportBug")}>
          <StyledBookmark
            type="primary"
            icon={<img src={icons.UserInvoiceIcon} alt="" />}
            onClick={handleOpenFeedbackModal}
          />
        </Tooltip>
      )}

      <ModalWithHeader
        title={t("title")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        <StyledFormContainer>
          <FeedbackForm
            isFormSubmitted={isSubmitted}
            setIsFormSubmitted={setIsSubmitted}
            onSuccess={() => {
              setModalVisible(false);
              setIsSubmitted(false);
            }}
          />
        </StyledFormContainer>
      </ModalWithHeader>
    </>
  );
};

export default FeedbackModal;
