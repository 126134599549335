import React, { Dispatch, ReactNode, SetStateAction } from "react";
import styles from "./ModalWithHeader.module.css";
import Close from "../../assets/svg/Close.svg";
import Warning from "../../assets/svg/warning fill.svg";

interface IProps {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  title: string;
  width?: string;
}

const ModalWithHeader = ({
  modalVisible,
  setModalVisible,
  title,
  children,
  width,
}: IProps) => {
  const modalWidthStyle = width && {
    width: width,
  };

  return (
    <div
      className={
        modalVisible ? `${styles.overlay} ${styles.active}` : styles.overlay
      }
      onClick={() => setModalVisible(false)}
    >
      <div
        className={
          modalVisible ? `${styles.modal} ${styles.active}` : styles.modal
        }
        onClick={(event) => event.stopPropagation()}
        style={modalWidthStyle}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>
            {title === "Ліміт перевищено" ? (
              <>
                <img src={Warning} alt="warning" /> {title}
              </>
            ) : (
              title
            )}
          </h2>
          <img onClick={() => setModalVisible(false)} src={Close} alt="close" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalWithHeader;
